import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "kt_content_container",
  class: "d-flex flex-column-fluid align-items-start"
}
const _hoisted_2 = {
  class: "content flex-row-fluid",
  id: "kt_content"
}
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = {
  class: "nav nav-pills mb-3 mx-auto flex-shrink-0 flex-center flex-wrap border-transparent fs-6 fw-bolder",
  id: "pills-tab",
  role: "tablist"
}
const _hoisted_6 = {
  class: "nav-item my-3",
  role: "presentation"
}
const _hoisted_7 = {
  class: "nav-item my-3",
  role: "presentation"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Channel = _resolveComponent("Channel")!
  const _component_Event = _resolveComponent("Event")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("ul", _hoisted_5, [
              _createVNode("li", _hoisted_6, [
                _createVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ChangeTab('channel'))),
                  class: "btn fw-boldest nav-link btn-color-gray-700 px-3 px-lg-8 mx-1 text-uppercase nav-link active",
                  id: "pills-home-tab",
                  "data-bs-toggle": "pill",
                  "data-bs-target": "#pills-home",
                  type: "button",
                  role: "tab",
                  "aria-controls": "pills-home",
                  "aria-selected": "true"
                }, " Channels ")
              ]),
              _createVNode("li", _hoisted_7, [
                _createVNode("button", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ChangeTab('event'))),
                  class: "btn fw-boldest nav-link btn-color-gray-700 px-3 px-lg-8 mx-1 text-uppercase",
                  id: "pills-profile-tab",
                  "data-bs-toggle": "pill",
                  "data-bs-target": "#pills-profile",
                  type: "button",
                  role: "tab",
                  "aria-controls": "pills-profile",
                  "aria-selected": "false"
                }, " Events ")
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode("div", null, [
      (this.tabName == 'channel')
        ? (_openBlock(), _createBlock("div", _hoisted_8, [
            _createVNode(_component_Channel)
          ]))
        : _createCommentVNode("v-if", true),
      (this.tabName == 'event')
        ? (_openBlock(), _createBlock("div", _hoisted_9, [
            _createVNode(_component_Event)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}