
import { defineComponent, ref, onMounted } from "vue";
import Channel from "@/views/communicationService/channel.vue";
import Event from "@/views/communicationService/event.vue";

export default defineComponent({
  name: "service",
  components: {
    Channel,
    Event,
  },
  data() {
    return {
      tabName: "channel",
    };
  },
  methods: {
    ChangeTab(val) {
      this.tabName = val;
    },
  },
});
